<template>
  <div class="page">
    <!-- <div id="to_app_div_pc" data-aos="zoom-in">去 手机应用商店 下载【花样起名】APP</div> -->
    <div class="blue_bk">
      <div class="page1">
        <h1 data-aos="fade-up" >花样起名 用数据拓展起名思路。</h1>
        <div class="c" id="about"  >
          <div class="left">
            <div data-aos="fade-up">
              <p class="end_about">当 <b>古今文化</b> 遇上 <b>Ai</b></p>
            </div>

            <div data-aos="fade-up">
              <p>3万个成语，15万首古诗词</p>
              <p class="end_about">数百万篇文章，千万句日常对话</p>
            </div>
            <div data-aos="fade-up" >
              <p>获得百万人名书名地名</p>
              <p class="end_about">数十万实体、过亿词性搭配</p>
            </div>

           <div data-aos="fade-up" >
             <p>从中寻觅好名字</p>
           </div>

          </div>
          <div class="right"  data-aos="zoom-in">
            <!-- <img :src="this.base_img + this.store_info.erweima" > -->
            <h1 data-aos="fade-in" >花样起名</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="pink_bk">
      <div class="page1">
        <h1 data-aos="fade-up" >多种独有起名方案，Ai + 大数据支持</h1>
        <div class="c" id="kefu">
          <div data-aos="fade-up">
            <h3>成语谐音起名</h3>
            <p class="ask">姓李，帮找个有文化的名字？</p>
            <p class="answer">李可喻？不可理喻，另几个要不要看看</p>
          </div>

          <div data-aos="fade-up">
            <h3>含母姓音谐音起名</h3>
            <p class="ask">老公姓江，我姓周，叫什么好？</p>
            <p class="answer">江雨舟？江与(周)在一起，也很有场景感</p>
          </div>
          <div data-aos="fade-up">
            <h3>高频词谐音起名</h3>
            <p class="ask">姓吴，有什么很有张力的名字？</p>
            <p class="answer">吴所谓？"无所谓"在日常生活中频次很高</p>
          </div>

          <div data-aos="fade-up">
            <h3>知识图谱起名</h3>
            <p class="ask">姓石，这个姓难起名怎么办？</p>
            <p class="answer">石成玉？石头的高阶形态为玉，有上升力</p>
          </div>
        </div>
      </div>
    </div>
    <div class="blue_bk">
      <div class="page1">
        <h1  data-aos="fade-up" >不是推销名字的商店，而是顺应用户喜好 挖掘名字的工具</h1>
        <div class="c" id="ability">
          <div data-aos="fade-up" >
            <h3>起名测试</h3>
            <p class="desc">用考题的形式帮用户巩固起名注意事项</p>
          </div>
          <div data-aos="fade-up" >
            <h3>投票互动</h3>
            <p class="desc">几个好名字犹豫不决，让亲友帮参谋参谋</p>
          </div>
          <div data-aos="fade-up" >
            <h3>配字参考</h3>
            <p class="desc">已有心仪字去搭配另一个，无需翻字典</p>
          </div>
          <div data-aos="fade-up" >
            <h3>名人明星</h3>
            <p class="desc">查看历史名人、流行明星，参考起名</p>
          </div>
        </div>
      </div>
    </div>

    <div class="pink_bk">
      <div class="page1">
        <h1  data-aos="fade-up" >一个潜在名字，多角度分析</h1>
        <div class="c" id="analyse">
          <div class="left" data-aos="zoom-in"   >
            <img :src="this.base_img + 'bihua.png'" >
            <p class="title">笔画分析</p>
          </div>
          <div class="right">
              <div class="ex" data-aos="fade-up" >
                <h3>含义分析</h3>
                <p class="name">刘余香</p>
                <p class="desc">（人走过）香气还在</p>
              </div>
              <div class="ex"   data-aos="fade-up" >
                <h3>男女分析</h3>
                <p class="name">刘余香</p>
                <p class="desc">女性概率74%，男性14%，模糊区间12%</p>
              </div>
              <div class="ex"   data-aos="fade-up">
                <h3>发音分析</h3>
                <p class="name">石撼山</p>
                <p class="desc">撼和山的韵母同为an，有拗口风险</p>
              </div>
              <div class="ex" data-aos="fade-up" >
                <h3>实体分析</h3>
                <p class="name">张英武</p>
                <p class="desc">英武 - yingwu - 鹦鹉，有被起外号的风险</p>
              </div>
              <div class="ex" data-aos="fade-up" >
                <h3>相关成语</h3>
                <p class="name">王卓群</p>
                <p class="desc">卓尔不群-才德超出寻常，与众不同</p>
              </div>
              <div class="ex" data-aos="fade-up" >
                <h3>更多</h3>
                <p class="name"></p>
                <p class="desc">五行分析、名人明星、字含义...</p>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="blue_bk">
      <div class="page1">
        <h1  data-aos="fade-up" >用心起个好名字，是给小孩最棒的诞辰礼！</h1>
        <p  data-aos="fade-up" >-- 花样起名 自然语言分析技术团队 --</p>
        <div class="c" id="last" data-aos="zoom-in">
          <!-- <img :src="this.base_img + this.store_info.erweima" > -->
          <img :src="this.base_img + 'logo.png'" >
          <p>去应用商店下载app</p>
        </div>
      </div>
    </div>
    <div id="footer">
      <div>关于我们：泰安道听途想网络科技有限公司</div>
      <div><a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index" style="text-decoration:underline">鲁ICP备20026180号-3</a></div>
      <div>微信客服：wu_duo_hua</div>
      <div style="display:inline-block; vertical-align:middle;">
        <img style="width:0.15rem;high:0.15rem; margin-right:0.03rem;" src="@/assets/img/gongan.png"  alt="">
        <span>37092102000111</span>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import axios from 'axios'
import Utils from '@/utils/utils'

export default {
  name: 'index_pc',
  store,
  data () {
    return {
      base_img: store.state.base_img,
      store_info: {
        erweima: ''
      }
    }
  },
  mounted () {
    this.index_pc()
  },
  methods: {
    index_pc () {
      axios.post('/index_pc/', { sid: this.$route.query.sid })
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          this.store_info = res.data.data.store_info
        })
    }
  }
}
</script>

<style scoped>
body {
  color:#fefefe;
  font: normal 18px "microsoft yahei",Arial,sans-serif;
  text-align: center;
  line-height: 1.8;
}
body,div,p,ul,li{
    margin:0;
    padding:0;
}
ul,li{
    list-style: none;
}
a{
    text-decoration:none
}
.page{
  font-size: 0.22rem;
  color: #fefefe;
}
.tips, .end{
  font-size: 0.22rem;
  color: #fefefe;
}

.page1{
    width: 8rem;
    height: 4.5rem;
    margin: 0rem auto;

}
.blue_bk,.pink_bk{
    width: 100%;
    padding: 0.4rem 0rem;

}
.blue_bk{
    background: #81D3F8;
}
.pink_bk{
    background: #FFB6C1;
}

img{
    width: 200px;
}

h1{
    height: 0.36rem;
    line-height: 0.36rem;
    padding: 0.20rem 0rem;
    font-size: 0.30rem;
}
#about div{
    vertical-align: middle;
}
#about .left{
  margin-top: 0.5rem;
  width: 3.6rem;
  vertical-align: middle;
  font-size: 0.16rem;

}
#about .left p{
  font-size: 0.16rem;
}
#about .end_about{
  margin: 0.12rem;
  color: #f8f8f8;
}
#about .right{
  margin-top: 0.30rem;
  width: 3.6rem;
  vertical-align: middle;
}
#about .right img{
  margin-top: 0.40rem;
}
#about .right h1{
  line-height: 0.50rem;
  margin: 0rem;
  padding: 0rem;
}
#about .right h1.exam{
  font-size: 0.16rem;
  line-height: 0.16rem;
  margin: 0rem;
  font-weight: normal;
}

.c div{
  display: inline-block;

}
p.title{
  font-size: 0.3rem;
  font-weight: bold;
}
#about .right img{
    width: 2.20rem;
    height: 2.20rem;
    border-radius: 1.10rem;
}

#ability div{
    width: 3.20rem;
    padding: 0.30rem 0.10rem;
    margin: 0.16rem 0.16rem;
    border: 0.01rem dashed #f8f8f8;
    border-radius: 0.10rem;
    background: rgba(255,255,255,0.2);
}

#ability h3{
    font-size: 0.30rem;
    padding-bottom: 0.15rem;
}
#ability .desc{
  font-size: 0.16rem;
}

#analyse .left,#analyse .right{
    vertical-align: middle;
}
#analyse .left{
    width: 3rem;
    margin-right: 0.20rem;
}
#analyse .right{
     width: 4.4rem;
}
#analyse img{
    width: 3rem;
    border-radius: 0.10rem;
    margin:0.20rem 0rem;
}
#analyse  .ex{
    text-align: left;
    width: 1.8rem;
    height: 0.8rem;
    border: 0.01rem dashed #f8f8f8;
    padding: 0.10rem 0.10rem;
    border-radius: 0.10rem;
    margin: 0.10rem 0.05rem ;
    float: left;
    background: rgba(255,255,255,0.2);

}
#analyse .desc{
  font-size: 0.15rem;
}
#kefu div{
    width: 3.60rem;
    padding: 0.20rem 0.0rem;
    margin: 0.10rem 0.16rem;
    border: 0.01px dashed #f8f8f8;
    border-radius: 0.10rem;
    background: rgba(255,255,255,0.2);

}
#kefu div h3{
    font-size: 0.30rem;
    font-weight: bold;

}

#kefu div p{
    margin-top: 0.1rem;
    font-size: 0.16rem;
    text-align: left;
    margin-left: 0.30rem;

}
#kefu div p span{
    color: #aaa;

}
#analyse  h3 ,#analyse  .ex .name{
  display: inline-block;
  vertical-align: middle;
}
#analyse  h3 {
    font-size: 0.22rem;
    font-weight: bold;
}
#analyse  .ex .name{
    float: right;
    color: #f8f8f8;
    line-height: 0.36rem;
}

#about b{
  border: 0.01rem solid #fefefe;
  padding: 0.03rem 0.06rem;
  border-radius: 0.04rem;
}

#about .left div{
  display: block;
}

#footer{
    background: #555;
    text-align: center;
}
#footer div{
    display: inline-block;
    padding: 0.30rem 0.20rem;
    font-size: 0.16rem;

}
#footer a{
    color: #fefefe;
}
#last {
  text-align: center;
}
#last img {
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  margin-top: 0.5rem;
}
.page{
  padding-bottom: 0rem;
}
#to_app_div_pc{
  position: fixed;
  background-color: #555555;
  text-align: center;
  z-index: 100000;
  top: 0rem;
  height: 0.4rem;
  line-height: 0.4rem;
  padding-bottom: 0.02rem;
  width: 6rem;
  margin: 0rem 2rem 0rem 2rem;
  border-radius: 0rem 0rem 0.5rem 0.5rem ;
  box-shadow: -0.2rem -0.2rem -0.3rem #888888;
}

</style>
